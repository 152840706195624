/* global PRODUCTION VUE */
// import Vue from 'vue';
import { metrics } from './theme/scripts/ferg-metrics.js';

console.log(`${PRODUCTION ? 'Production Environment' : 'Dev Environment'}`);

if (PRODUCTION) {
	// Production-specific settings go here.
	// console.log = () => {}; 
} else {
	metrics();
}

document.addEventListener('DOMContentLoaded', function() {
	if (!PRODUCTION) console.log('DOMContentLoaded');
	
	// initVue();
	handleHamburgers();
	handleDropdowns();
	exitDropdowns();
	slickSlidesBanner();
	slickSlidesModels();
	slickSlidesVehicle();
	handleSeeLineUp();
	handleSearch();
});

// Slick Code For Models On Homepage
function slickSlidesModels() {
	jQuery(".models-slider").slick({
		infinite: true,
		lazyLoad: true,
		pauseOnHover: true,
		arrows: true,
		dots: false,
		animation: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		appendArrows: jQuery('.arrows'),
		prevArrow: '<i class="fas fa-chevron-left button-prev"></i>',
		nextArrow: '<i class="fas fa-chevron-right button-next"></i>',
		autoplay: true,
  		autoplaySpeed: 5000,
		  responsive: [
			{
				breakpoint: 1250,
				settings: {
				  slidesToShow: 3,
				},
			  },
			{
			  breakpoint: 850,
			  settings: {
				slidesToShow: 2,
			  },
			},
			{
			  breakpoint: 700,
			  settings: {
				slidesToShow: 1,
			  },
			}
		],
	});
}

function slickSlidesBanner() {
	jQuery('.hero-slider').slick({
		slidesToShow: 1,
		lazyLoad: true,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 5000,
	});
}

function slickSlidesVehicle() {
	jQuery('.vehicle-photo-slider').slick({
		infinite: true,
		lazyLoad: true,
		pauseOnHover: true,
		arrows: true,
		dots: false,
		animation: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		appendArrows: jQuery('.arrows'),
		prevArrow: '<i class="fas fa-chevron-left button-prev"></i>',
		nextArrow: '<i class="fas fa-chevron-right button-next"></i>',
		autoplay: false,
		  responsive: [
			{
				breakpoint: 1250,
				settings: {
				  slidesToShow: 2,
				},
			  },
			{
			  breakpoint: 850,
			  settings: {
				slidesToShow: 1,
			  },
			}
		],
	});
}

function handleHamburgers() {
	// Get all "navbar-burger" elements
	const navbarBurgers = document.querySelectorAll('.navbar-burger'); // Check if there are any navbar burgers
	
	if (navbarBurgers.length > 0) {
		// Add a click event on each of them
		navbarBurgers.forEach(function(el) {
			el.addEventListener('click', function() {
				// Get the target from the "data-target" attribute
				const target = el.dataset.target;
				const $target = document.getElementById(target); // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
				const howToBuyButton = document.getElementById("howToBuyButton");
				
				el.classList.toggle('is-active');
				$target.classList.toggle('is-active');
				howToBuyButton.classList.toggle('is-active');
			});
		});
	}
}

function handleDropdowns() {
	const items = document.querySelectorAll('.menu-item');

	items.forEach(item => {
		item.addEventListener('click', function handleClick() {
			if(item.classList.contains("menu-item-has-children")) {
				item.classList.contains("is-active") ? item.classList.remove("is-active") : item.classList.add("is-active");
			}
		});
	});
}

function exitDropdowns() {
	const items = document.querySelectorAll('.menu-item');

	items.forEach(item => {
		item.addEventListener('click', function handleClick() {
			if(item.classList.contains("models-main-link") || item.classList.contains("menu-item-type-custom")) {
				return;
			} else {
				document.getElementById("main-nav").classList.add("hide-menu");
				document.getElementById("howToBuyButton").classList.add("hide-menu");
			}
		});
	});
}

function handleSeeLineUp(){
	const lineUpButton = document.getElementById('seeLineUp');
	if(!lineUpButton) return;

	lineUpButton.addEventListener('click', function(){
		document.getElementById('applicationsListing').scrollIntoView({
			behavior: 'smooth'
		});
	});
}

function handleSearch(){
	const searchButton = document.querySelector('#navSearchButton');
	const searchButtonMobile = document.querySelector('#navSearchButtonMobile');
	const searchForm = document.querySelector('#navSearchForm');

	searchButton.addEventListener('click', function(){
		searchForm.classList.toggle('is-active');
		searchButton.classList.toggle('is-active');
	});

	searchButtonMobile.addEventListener('click', function(){
		searchForm.classList.toggle('is-active');
		searchButtonMobile.classList.toggle('is-active');
		searchForm.querySelector('input').focus();
	});
}
