/* global ga */
import { getTTFB, getFID, getLCP } from 'web-vitals';

export const metrics = function() {
	const styles = 'padding: 1em; border-radius: 1em;';
	getTTFB((obj) => {
		console.log(`%c FergPerf - TTFB: ${obj.value} | Good: <200ms`, `${styles} background:${obj.value < 200 ? 'green' : 'yellow'}`);

		if (typeof ga !== 'undefined') {
			ga('send', 'timing', 'Web Vitals', 'ttfb', obj.value);
		}
	});
	getFID((obj) => {
		console.log(`%c FergPerf - FID: ${obj.value} | Good: <100ms`, `${styles} background:${obj.value < 100 ? 'green' : 'yellow'}`);
		
		if (typeof ga !== 'undefined') {
			ga('send', 'timing', 'Web Vitals', 'fid', obj.value);
		}
	});	
	getLCP((obj) => {
		console.log(`%c FergPerf - LCP: ${obj.value} | Good: <2500ms`, `${styles} background:${obj.value < 2500 ? 'green' : 'yellow'}`);
		if (typeof ga !== 'undefined') {
			ga('send', 'timing', 'Web Vitals', 'lcp', obj.value);
		}
	});	
};
